<template>
  <section>
    <article id="main" class="border-bottom">
      <div>
        <carousel
          :items-to-show="numItemsToShow"
          :mouse-drag="mainSlideList.length !== 1"
          :touch-drag="mainSlideList.length !== 1"
          ref="carouselRef"
          :wrap-around="mainSlideList.length > 1"
          :loop="mainSlideList.length !== 1"
          :autoplay="autoplayInterval"
        >
          <slide v-for="item in mainSlideList" :key="item.nid">
            <div class="w-full h-full carousel__item">
              <div class="h-[574px] mb-10">
                <template v-if="itemIsVideo(item)">
                  <video
                    class="block object-cover w-full h-full"
                    :src="'https://dcamp.kr' + item.image"
                    preload="auto"
                    muted="muted"
                    autoplay
                    volume="0"
                    playsinline
                    @play="pauseCarouselAutoplay"
                    @ended="resumeCarouselAutoplay($event)"
                  ></video>
                </template>
                <template v-else>
                  <img
                    class="block object-cover w-full h-full"
                    :src="'https://dcamp.kr' + item.image"
                    :alt="item.title"
                  />
                </template>
              </div>
              <div class="inner w-[90%] mx-auto">
                <h2
                  class="text-center BoldH3 md:text-[32px] md:leading-[40px] mb-4"
                >
                  {{ item.title }}
                </h2>
                <p class="text-center RegularH6 text-[#495057] mb-2">
                  {{ item.content }}
                </p>
                <a
                  class="inline-block mb-6 BoldH6 text-Primary-default hover:opacity-80"
                  :href="item.btn_link"
                  target="_blank"
                  >{{ item.btn_name }}</a
                >
              </div>
            </div>
          </slide>

          <template #addons>
            <pagination />
          </template>
        </carousel>
      </div>
    </article>
    <article class="md:py-[80px] py-[40px] border-bottom">
      <div class="lg:max-w-[1216px] mx-auto w-[90%]">
        <ul
          class="grid grid-cols-2 gap-y-[24px] md:gap-y-[48px] gap-x-8 lg:grid-cols-4 xl:gap-x-8 lg:max-w-[1216px] md:mx-auto mb-[24px] md:mb-[48px]"
        >
          <li
            class="relative group main-card-list"
            v-for="(item, index) in dday.rows && dday.rows.slice(0, 4)"
            :key="index"
          >
            <a
              :href="'https://dcamp.kr' + item.view_dcamp_event"
              target="_blank"
            >
              <div class="relative">
                <div
                  class="lg:max-w-[280px] h-0 pb-[99%] overflow-hidden relative z-0 bg-Neutrals-white border border-Neutrals-grey3 group-hover:opacity-75"
                >
                  <img
                    class="res-img"
                    :src="'https://dcamp.kr' + item.field_image"
                    :alt="item.title"
                  />
                </div>
                <div
                  :class="{
                    'status recruiting': item.status === '모집중',
                    'status closed': item.status === '마감',
                  }"
                >
                  {{ item.status }}
                </div>
              </div>
              <div class="mt-6">
                <p class="mb-4 BoldH5 md:text-[20px] md:leading-[24px]">
                  {{ item.title }}
                </p>
              </div>
            </a>
            <span class="RegularH6 text-Neutrals-grey6">
              {{ item.field_event_date }}
            </span>
          </li>
        </ul>
        <ul
          class="grid grid-cols-2 gap-y-[24px] md:gap-y-[48px] gap-x-8 lg:grid-cols-4 xl:gap-x-8 lg:max-w-[1216px] md:mx-auto mb-[24px] md:mb-[48px]"
        >
          <li
            class="relative group main-card-list"
            v-for="(item, index) in growthAndCommunity.rows &&
            growthAndCommunity.rows.slice(0, 4)"
            :key="index"
          >
            <a
              :href="'https://dcamp.kr' + item.view_dcamp_event"
              target="_blank"
            >
              <div class="relative">
                <div
                  class="lg:max-w-[280px] h-0 pb-[99%] overflow-hidden relative z-0 bg-Neutrals-white border border-Neutrals-grey3 group-hover:opacity-75"
                >
                  <img
                    class="res-img"
                    :src="'https://dcamp.kr' + item.field_image"
                    :alt="item.title"
                  />
                </div>
                <div
                  :class="{
                    'status recruiting': item.status === '모집중',
                    'status closed': item.status === '마감',
                  }"
                >
                  {{ item.status }}
                </div>
              </div>
              <div class="mt-6">
                <p class="mb-4 BoldH5 md:text-[20px] md:leading-[24px]">
                  {{ item.title }}
                </p>
              </div>
            </a>
            <span class="RegularH6 text-Neutrals-grey6">
              {{ item.field_event_date }}
            </span>
          </li>
        </ul>
        <ul
          class="grid grid-cols-2 gap-y-[24px] md:gap-y-[48px] gap-x-8 lg:grid-cols-4 xl:gap-x-8 lg:max-w-[1216px] md:mx-auto"
        >
          <li
            class="relative group main-card-list"
            v-for="(item, index) in globalAndCooperation.rows &&
            globalAndCooperation.rows.slice(0, 4)"
            :key="index"
          >
            <a
              :href="'https://dcamp.kr' + item.view_dcamp_event"
              target="_blank"
            >
              <div class="relative">
                <div
                  class="lg:max-w-[280px] h-0 pb-[99%] overflow-hidden relative z-0 bg-Neutrals-white border border-Neutrals-grey3 group-hover:opacity-75"
                >
                  <img
                    class="res-img"
                    :src="'https://dcamp.kr' + item.field_image"
                    :alt="item.title"
                  />
                </div>
                <div
                  :class="{
                    'status recruiting': item.status === '모집중',
                    'status closed': item.status === '마감',
                  }"
                >
                  {{ item.status }}
                </div>
              </div>
              <div class="mt-6">
                <p class="mb-4 BoldH5 md:text-[20px] md:leading-[24px]">
                  {{ item.title }}
                </p>
              </div>
            </a>
            <span class="RegularH6 text-Neutrals-grey6">
              {{ item.field_event_date }}
            </span>
          </li>
        </ul>
      </div>
    </article>
    <article class="md:py-[48px] py-[32px] border-bottom">
      <div class="lg:max-w-[1216px] mx-auto w-[90%]">
        <ul>
          <li
            class="flex flex-col justify-between gap-2 px-0 py-4 last:border-b-0 md:flex-row md:py-8 md:px-4 border-bottom md:gap-0 last:pb-0"
            v-for="item in eventPartnerList"
            :key="item.nid"
          >
            <div class="flex flex-col items-center md:flex-row">
              <p
                class="w-full md:w-auto text-left font-bold BoldTiny md:text-[16px] md:leading-[24px] text-Primary-default whitespace-nowrap"
              >
                {{ item.field_p_category }}
              </p>
              <div
                class="hidden md:flex h-[20px] md:h-[24px] mx-2 md:mx-4 border-left"
              ></div>
              <div class="w-full">
                <a
                  class="relative inline-block transition-all duration-300 BoldH6 hover:transform hover:scale-[1.01]"
                  :href="item.field_url"
                  target="_blank"
                  ><span v-html="item.title"></span>
                </a>
              </div>
            </div>
            <div class="flex items-center justify-end md:justify-center">
              <p
                class="RegularTiny md:text-[16px] md:leading-[24px] text-Neutrals-grey6 whitespace-nowrap pl-2"
              >
                {{ item.created }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </article>
    <article
      class="md:pt-[80px] pt-[40px] md:pb-[96px] pb-[48px] border-bottom"
    >
      <div class="lg:max-w-[1216px] mx-auto w-[90%] main-card-list">
        <h3 class="mb-4 BoldH3">프론트원 더 알아보기</h3>
        <ul
          class="grid md:gap-y-[48px] gap-y-[24px] gap-x-8 md:grid-cols-2 xl:gap-x-8"
        >
          <li>
            <router-link to="/frontone">
              <div
                class="md:max-w-[597px] h-0 pb-[60%] md:pb-[51.351%] overflow-hidden relative z-0 zoom-in"
              >
                <img
                  class="res-img"
                  :src="require('@/assets/Images/main_1.jpg')"
                  alt="main_1"
                />
              </div>
              <div class="mt-4 md:mt-6">
                <p class="BoldH4">프론트원 알아보기</p>
                <p class="mt-2 md:mt-4 RegularH6 text-Neutrals-grey6">
                  브랜드·파트너기관·주요연혁·오시는길
                </p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/facilities">
              <div
                class="md:max-w-[597px] h-0 pb-[60%] md:pb-[51.351%] overflow-hidden relative z-0 zoom-in"
              >
                <img
                  class="res-img"
                  :src="require('@/assets/Images/main_2.jpg')"
                  alt="main_2"
                />
              </div>
              <div class="mt-4 md:mt-6">
                <p class="BoldH4">업무와 휴식을 위한 공간</p>
                <p class="mt-2 md:mt-4 RegularH6 text-Neutrals-grey6">
                  공간구성·업무공간, 복지시설 등 주요시설 안내
                </p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/company">
              <div
                class="md:max-w-[597px] h-0 pb-[60%] md:pb-[51.351%] overflow-hidden relative z-0 zoom-in"
              >
                <img
                  class="res-img"
                  :src="require('@/assets/Images/main_3.jpg')"
                  alt="main_3"
                />
              </div>
              <div class="mt-4 md:mt-6">
                <p class="BoldH4">스타트업의 성장을 위한 공간</p>
                <p class="mt-2 md:mt-4 RegularH6 text-Neutrals-grey6">
                  입주 스타트업 · 졸업 스타트업
                </p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/contact">
              <div
                class="md:max-w-[597px] h-0 pb-[60%] md:pb-[51.351%] overflow-hidden relative z-0 zoom-in"
              >
                <img
                  class="res-img"
                  :src="require('@/assets/Images/main_4.jpg')"
                  alt="main_4"
                />
              </div>
              <div class="mt-4 md:mt-6">
                <p class="BoldH4">프론트원에 문의하기</p>
                <p class="mt-2 md:mt-4 RegularH6 text-Neutrals-grey6">
                  공동사업·제휴할인·공간투어·대관신청 등
                </p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </article>
    <article
      class="px-0 md:py-[56px] py-[28px] border-bottom bg-Neutrals-grey0"
    >
      <link
        rel="stylesheet"
        href="https://s3.ap-northeast-2.amazonaws.com/resource.stibee.com/subscribe/stb_subscribe_form_style.css"
      />
      <div
        id="stb_subscribe"
        class="flex justify-between flex-col md:flex-row !bg-transparent !border-0 !p-0 !w-[90%] max-w-[1216px] !mx-auto"
      >
        <div>
          <p
            class="!mb-2 md:!text-[24px] md:!leading-[32px] !text-[18px] !leading-[24px] font-PretendardBold md:w-[206px] w-full"
          >
            {{ footer.main }}
          </p>
          <p
            class="md:text-[16px] md:leading-[24px] !text-[14px] !leading-[24px] !text-Neutrals-grey7 font-Pretendard"
          >
            {{ footer.sub }}
          </p>
        </div>
        <div class="w-full max-w-[800px] md:!mt-0 !mt-3">
          <form
            action="https://stibee.com/api/v1.0/lists/pwqPNYLpy8hctkJ8om6rcnuFrIzC0Q==/public/subscribers?groupIds=150539"
            method="POST"
            target="_blank"
            accept-charset="utf-8"
            class="flex flex-col stb_form gap-y-3 gap-x-8"
            name="stb_subscribe_form"
            id="stb_subscribe_form"
            novalidate=""
          >
            <div
              class="flex flex-col justify-between gap-3 md:gap-8 md:flex-row"
            >
              <fieldset
                class="stb_form_set w-full max-w-full md:max-w-[384px] !pb-0"
              >
                <label
                  for="stb_email"
                  class="stb_form_set_label BoldTiny !pb-0"
                >
                  이메일 주소<span class="#5c328b">*</span>
                </label>
                <input
                  type="email"
                  class="stb_form_set_input w-full border max-h-[40px] px-2 py-4 RegularH6 appearance-none"
                  id="stb_email"
                  name="email"
                  required="required"
                  placeholder="이메일 주소"
                />
                <div class="stb_form_msg_error" id="stb_email_error"></div>
              </fieldset>
              <fieldset
                class="stb_form_set w-full max-w-full md:max-w-[384px] !pb-0"
              >
                <label for="stb_name" class="stb_form_set_label BoldTiny !pb-0">
                  이름
                </label>
                <input
                  type="text"
                  class="stb_form_set_input w-full border max-h-[40px] px-2 py-4 RegularH6 appearance-none"
                  id="stb_name"
                  name="name"
                  placeholder="이름"
                />
                <div class="stb_form_msg_error" id="stb_name_error"></div>
              </fieldset>
            </div>
            <!-- <fieldset class="stb_form_set !pb-0">
            <label for="stb_name" class="stb_form_set_label BoldTiny !pb-0">
              소속
            </label>
            <input
              type="text"
              class="stb_form_set_input w-full border max-h-[40px] px-2 py-4 RegularH6 appearance-none"
              id="stb_position"
              name="company"
              required="required"
              placeholder="소속"
            />
            <div class="stb_form_msg_error" id="stb_position_error"></div>
          </fieldset> -->
            <div
              class="stb_form_policy checkbox_wrap gap-2 col-[1/4] !mt-4 md:!mt-0 !pb-0 !flex"
            >
              <input
                type="checkbox"
                id="stb_policy"
                value="stb_policy_true"
                v-model="newsletterAgreement"
                name="newsletter-agreement"
              />
              <label for="stb_policy">
                <span>개인정보 수집·이용에 동의합니다.</span>
              </label>
              <div class="!pt-5 stb_form_msg_error" id="stb_policy_error"></div>
            </div>

            <div class="stb_form_result" id="stb_form_result"></div>

            <div class="col-[1/4]">
              <fieldset
                class="flex flex-col md:flex-row gap-2 col-[1/4] !mt-3 !pt-0 stb_form_set_submit"
              >
                <button
                  type="submit"
                  class="stb_form_submit_button !text-base font-bold !h-[48px] !w-full md:!w-[228px] max-w-full !border-solid !border-[1px] !border-Neutrals-grey9 BoldH6 hover:!bg-Neutrals-grey9 cursor-pointer hover:!text-Neutrals-white transition duration-300 ease-in-out !bg-transparent !text-Neutrals-grey9"
                  id="stb_form_submit_button"
                >
                  뉴스레터 구독하기
                </button>
                <a
                  href="http://dcamp.kr/newsletter"
                  target="_blank"
                  class="stb_form_submit_button flex justify-center items-center !text-base font-bold !h-[48px] !w-full md:!w-[228px] max-w-full !border-solid !border-[1px] !border-Neutrals-grey9 BoldH6 hover:!bg-Neutrals-grey9 cursor-pointer hover:!text-Neutrals-white transition duration-300 ease-in-out !bg-transparent !text-Neutrals-grey9"
                  id="stb_form_submit_button"
                >
                  콘텐츠 보러가기
                </a>
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import { reactive, toRefs, computed, ref, watch, nextTick } from "vue";
import axios from "axios";
import Cookies from "js-cookie";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "MainPage",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      // mainSlideList: [],
      dday: [],
      growthAndCommunity: [],
      globalAndCooperation: [],
      eventPartnerList: [],
      footer: {},

      error: null,
      newsletterAgreement: false,
    };
  },
  setup() {
    // 상태를 관리하는 객체를 만듭니다.
    const state = reactive({
      mainSlideList: [],
      carouselWidth: 0, // 캐러셀 구성요소의 너비
      slideWidth: 0, // 각 슬라이드의 너비
    });

    // 캐러셀 요소에 대한 참조를 가져오고 너비 변경 사항을 확인합니다.
    const carouselRef = ref(null);
    watch(carouselRef, (el) => {
      if (el) {
        state.carouselWidth = el.offsetWidth;
      }
    });

    const numItemsToShow = computed(() => {
      if (state.carouselWidth === 0 || state.slideWidth === 0) {
        return 1; // 캐러셀 및 슬라이드 너비를 사용할 수 있을 때까지 1개 항목 표시
      }
      const maxItems = Math.floor(state.carouselWidth / state.slideWidth); // 캐러셀에 들어갈 수 있는 최대 항목 계산
      return Math.min(state.mainSlideList.length, maxItems); // 최대 항목 또는 슬라이드 수 중 더 작은 항목 표시
    });

    nextTick(() => {
      const slideEl = document.querySelector(".slide");
      if (slideEl) {
        state.slideWidth = slideEl.offsetWidth;
      }
    });

    const itemIsVideo = (item) => {
      return item.image.endsWith(".mp4"); // 비디오 파일 확장자 확인
    };

    const autoplayInterval = ref(3000); // default interval

    const pauseCarouselAutoplay = () => {
      const activeSlide = document.querySelector("li.carousel__slide--active"); // 현재 활성화된 슬라이드 찾기
      if (activeSlide && activeSlide.querySelector("video")) {
        // 활성화된 슬라이드에 비디오가 있을때만 함수 실행
        // console.log("Pause called");
        autoplayInterval.value = 9999999; // 비디오가 재생 중이면 캐러셀 autoplay를 일시 중지합니다.
      }
    };

    const resumeCarouselAutoplay = (event) => {
      // console.log("Resume called");
      if (carouselRef.value) {
        carouselRef.value.next();
        autoplayInterval.value = 3000; // 비디오가 재생되지 않으면 캐러셀 autoplay를 재개합니다.
      }

      // console.log(carouselRef.value);

      const videoEl = event.target; // 이벤트 대상 가져오기
      videoEl.currentTime = 0; // 비디오 초기화
      videoEl.load(); // 비디오 재생
    };

    return {
      ...toRefs(state),
      carouselRef,
      numItemsToShow,
      itemIsVideo,
      pauseCarouselAutoplay,
      resumeCarouselAutoplay,
      autoplayInterval,
    };
  },
  async mounted() {
    let form = new FormData();
    form.append("campus", 2);
    try {
      const [
        mainSlideListResponse,
        ddayResponse,
        growthAndCommunityResponse,
        globalAndCooperationResponse,
        eventPartnerListResponse,
        footerResponse,
      ] = await Promise.all([
        axios.post(process.env.VUE_APP_API_KEY + "api/slide?lang=ko", form),
        axios.get(
          process.env.VUE_APP_API_KEY +
            "api/event/list?field_category[]=1&page=0&items_per_page=6&langcode[]=und&langcode[]=ko"
        ),
        axios.get(
          process.env.VUE_APP_API_KEY +
            "api/event/list?field_category[]=2&field_category[]=227&page=0&items_per_page=6&langcode[]=und&langcode[]=ko"
        ),
        axios.get(
          process.env.VUE_APP_API_KEY +
            "api/event/list?field_category[]=3&field_category[]=228&page=0&items_per_page=6&langcode[]=und&langcode[]=ko"
        ),
        axios.get(
          process.env.VUE_APP_API_KEY +
            "api/node/partner_program/list?langcode=ko",
          { responseType: "arraybuffer" }
        ),
        axios.get(process.env.VUE_APP_API_KEY + "api/footer?langcode=ko"),
      ]);

      this.mainSlideList = mainSlideListResponse.data;
      this.dday = ddayResponse.data;
      this.growthAndCommunity = growthAndCommunityResponse.data;
      this.globalAndCooperation = globalAndCooperationResponse.data;
      const decoder = new TextDecoder("utf-8");
      this.eventPartnerList = JSON.parse(
        decoder.decode(eventPartnerListResponse.data)
      );
      this.footer = footerResponse.data;

      this.calculateStatus(this.dday.rows);
      this.calculateStatus(this.growthAndCommunity.rows);
      this.calculateStatus(this.globalAndCooperation.rows);

      var STBSUBFORM = {
        init: function () {
          var errorMsgs = document.getElementsByClassName("stb_form_msg_error");
          errorMsgs = [].slice.call(errorMsgs);
          for (var index = 0; index < errorMsgs.length; index++) {
            errorMsgs[index].style.display = "none";
          }
          var resultDom = document.getElementById("stb_form_result");
          resultDom.style.display = "none";
          resultDom.className = resultDom.className.replace("success", "");
          resultDom.className = resultDom.className.replace("fail", "");
        },
        formRequest: function (e) {
          e.preventDefault();
          STBSUBFORM.init();
          if (document.getElementById("stb_recaptcha")) {
            document.getElementById("stb_recaptcha").value =
              grecaptcha.getResponse();
          }
          var value = STBSUBFORM.getValue();
          if (STBSUBFORM.validate(value)) {
            var value = STBSUBFORM.serializeValue(value);
            value = value.replace("&policy=stb_policy_true", "");
            value = value.replace("ad_agreement", "receiveMarketingMailAgreed");
            var endpoint = STBSUBFORM.form.action;
            STBSUBFORM.xhrRequest(endpoint, value);
            if (document.getElementById("stb_recaptcha")) {
              grecaptcha.reset();
            }
          }
        },
        validateEmail: function (email) {
          var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        },
        // validatePhoneNumber: function (event) {
        //   if (event.keyCode === 8) {
        //     return;
        //   }
        //   if (event.keyCode === 189) {
        //     event.preventDefault();
        //   }
        //   if (event.target.value.length >= 13) {
        //     event.preventDefault();
        //   }
        //   var n = event.target.value.replace(/[^0-9-]/gi, "");
        //   event.target.value = n;

        //   var t = event.target.value.split("");

        //   var startDigit = event.target.value.startsWith("02") ? 2 : 3;
        //   var endDigit = event.target.value.startsWith("02") ? 6 : 8;

        //   if (event.target.value.length === 3) {
        //     t.splice(startDigit, 0, "-");
        //     event.target.value = t.join("");
        //   }

        //   if (event.target.value.length === 8) {
        //     t.splice(endDigit, 0, "-");
        //     event.target.value = t.join("");
        //   }

        //   if (event.target.value.length === 12) {
        //     event.target.value = event.target.value.replace(/-/gi, "");
        //     var t = event.target.value.split("");
        //     t.splice(startDigit, 0, "-");
        //     t.splice(7, 0, "-");
        //     event.target.value = t.join("");
        //   }

        //   if (event.target.value.length === 13) {
        //     event.target.value = event.target.value.replace(/-/gi, "");
        //     var t = event.target.value.split("");
        //     t.splice(startDigit, 0, "-");
        //     t.splice(8, 0, "-");
        //     event.target.value = t.join("");
        //   }
        // },
        validate: function (values) {
          for (var index = 0; index < values.length; index++) {
            var item = values[index];

            if (
              (item.isRadio &&
                item.required &&
                !item.checkedOption &&
                !item.checkedEtc) ||
              (item.isRadio &&
                item.required &&
                item.checkedEtc &&
                item.value.length === 0)
            ) {
              var itemLabel = item.parentElement.parentElement.parentElement
                .querySelector("label")
                .innerText.replace("*", "");
              STBSUBFORM.showError(
                item.id,
                itemLabel +
                  STBSUBFORM.josa(itemLabel) +
                  STBSUBFORM.errorMsg.ITEM_REQUIRED
              );
              return false;
            }

            if (item.id === "stb_email" && item.value.length === 0) {
              STBSUBFORM.showError(item.id, STBSUBFORM.errorMsg.EMAIL_REQUIRED);
              return false;
            }
            if (
              item.id === "stb_email" &&
              !STBSUBFORM.validateEmail(item.value)
            ) {
              STBSUBFORM.showError(item.id, STBSUBFORM.errorMsg.EMAIL_FORMAT);
              return false;
            }
            if (item.required && item.value.length === 0) {
              var itemLabel = item.parentElement
                .querySelector("label")
                .innerText.replace("*", "");
              STBSUBFORM.showError(
                item.id,
                itemLabel +
                  STBSUBFORM.josa(itemLabel) +
                  STBSUBFORM.errorMsg.ITEM_REQUIRED
              );
              return false;
            }
            if (item.id === "stb_policy" && !item.checked) {
              STBSUBFORM.showError(
                item.id,
                STBSUBFORM.errorMsg.PRIVACY_AGREEMENT
              );
              return false;
            }
            // if (
            //   item.id === "stb_ad_agreement" &&
            //   !item.checked &&
            //   item.required
            // ) {
            //   STBSUBFORM.showError(item.id, STBSUBFORM.errorMsg.AD_AGREEMENT);
            //   return false;
            // }
            if (item.id === "stb_$is_sms_agreed" && !item.checked) {
              if (document.getElementById("stb_$phone").value.length !== 0) {
                STBSUBFORM.showError(
                  item.id,
                  STBSUBFORM.errorMsg.SMS_AGREEMENT
                );
                return false;
              }
            }
            // if (
            //   item.id === "stb_$phone" &&
            //   item.value.length > 13 &&
            //   item.value.length !== 0
            // ) {
            //   STBSUBFORM.showError(item.id, STBSUBFORM.errorMsg.PHONE_FORMAT);
            //   return false;
            // }
            // if (
            //   item.id === "stb_$phone" &&
            //   item.value.length === 0 &&
            //   document.getElementById("stb_$is_sms_agreed").checked
            // ) {
            //   STBSUBFORM.showError(item.id, STBSUBFORM.errorMsg.PHONE_REQUIRED);
            //   return false;
            // }
            if (item.id === "stb_recaptcha" && grecaptcha.getResponse() == "") {
              STBSUBFORM.showError(
                item.id,
                STBSUBFORM.errorMsg.CAPTCHA_REQUIRED
              );
              return false;
            }
          }
          return true;
        },
        errorMsg: {
          EMAIL_REQUIRED: "이메일 주소는 필수입니다.",
          EMAIL_FORMAT: "잘못된 이메일 주소입니다.",
          ITEM_REQUIRED: " 입력하세요.",
          PRIVACY_AGREEMENT: "개인정보 수집 및 이용에 동의해주세요.",
          // AD_AGREEMENT: "광고성 정보 수신에 동의해주세요.",
          // SMS_AGREEMENT: "문자 메시지 수신에 동의해 주세요.",
          // PHONE_FORMAT: "잘못된 전화번호입니다.",
          // PHONE_REQUIRED: "전화번호를 입력하세요.",
          CAPTCHA_REQUIRED: "자동입력 방지를 확인해주세요.",
        },
        showError: function (itemId, msg) {
          var domName = itemId + "_error";
          var dom = document.getElementById(domName);
          dom.innerText = msg;
          dom.style.display = "block";
        },
        xhrRequest: function (endpoint, value) {
          STBSUBFORM.toggleButtonActivation(false);
          if (window.XDomainRequest) {
            var xdr = new XDomainRequest();
            xdr.open("POST", endpoint);
            xdr.onload = function () {
              if (xdr.responseText.indexOf("@ERROR_MSG") !== -1) {
                STBSUBFORM.showResult(xdr.responseText, "fail");
              } else {
                STBSUBFORM.showResult(xdr.responseText, "success");
              }
            };
            setTimeout(function () {
              xdr.send(value);
            }, 0);
          } else {
            var xhr = new XMLHttpRequest();
            xhr.open("POST", endpoint, true);
            xhr.setRequestHeader(
              "Content-Type",
              "application/x-www-form-urlencoded"
            );
            xhr.onreadystatechange = function () {
              if (
                this.readyState === XMLHttpRequest.DONE &&
                this.status === 200
              ) {
                if (this.response.indexOf("@ERROR_MSG") !== -1) {
                  STBSUBFORM.showResult(this.response, "fail");
                } else {
                  STBSUBFORM.showResult(this.response, "success");
                }
              }
            };
            xhr.send(value);
          }
        },
        serializeValue: function (nodeList) {
          nodeList = [].slice.call(nodeList);
          var serializeText = "";
          for (var index = 0; index < nodeList.length; index++) {
            var itemKey = nodeList[index].id.replace("stb_", "");
            var itemValue = nodeList[index].value;
            if (itemKey === "$phone") {
              itemValue = itemValue.replace(/-/gi, "");
            }
            if (itemKey === "$is_sms_agreed") {
              itemValue = nodeList[index].checked ? "Y" : "N";
            }
            // if (itemKey === "ad_agreement") {
            //   itemValue = nodeList[index].checked ? true : false;
            // }
            serializeText += itemKey + "=";
            serializeText += encodeURIComponent(itemValue);
            if (index + 1 !== nodeList.length) {
              serializeText += "&";
            }
          }
          return serializeText;
        },
        getValue: function () {
          var inputValues = STBSUBFORM.form.querySelectorAll("input, select");
          const values = Array.from(inputValues);

          let radioFilter = [];
          STBSUBFORM.form
            .querySelectorAll('input[type="radio"]')
            .forEach((element) => {
              const indexOnArray = radioFilter.findIndex(
                (item) => item.id === element["id"]
              );
              if (indexOnArray === -1) {
                radioFilter.push({
                  id: element["id"],
                  required: element["required"],
                  checked: element["checked"],
                });
              } else {
                if (element["checked"]) {
                  radioFilter[indexOnArray].checked = true;
                  if (element["value"] === "기타") {
                    radioFilter[indexOnArray].etcChecked = true;
                  }
                }
              }
            });

          var filteredValues = values.map((element) => {
            const filter = radioFilter.find(
              (item) => item.id === element["id"]
            );
            if (filter && !filter.checked) {
              element.isRadio = true;
              element.checkedOption = false;
              element.checkedEtc = false;
              return element;
            } else if (filter && filter.checked && !filter.etcChecked) {
              element.isRadio = true;
              element.checkedOption = true;
              element.checkedEtc = false;
              return element;
            } else if (filter && filter.checked && filter.etcChecked) {
              element.isRadio = true;
              element.checkedOption = false;
              element.checkedEtc = true;
              return element;
            } else {
              return element;
            }
          });

          //input 필터링
          var result = filteredValues.filter(
            (element) =>
              !element.isRadio ||
              (element.isRadio &&
                element.checkedEtc &&
                element["type"] === "text") || //라디오, etc 가 체크된 경우, text만 남기기
              (element.isRadio &&
                element.checkedOption &&
                element["type"] === "radio" &&
                element.checked) || //라디오, etc 아닌 것이 체크됨
              (element.isRadio &&
                element.required &&
                !element.checkedOption &&
                !element.checkedEtc) //라디오, 필수인데 체크 안됨
          );

          return result;
        },
        showResult: function (response, status) {
          var resMsg;
          var resultDom = document.getElementById("stb_form_result");
          if (status === "fail") {
            var resMsg =
              "<p>" + response.split("ERROR_MSG:")[1].split("-->")[0] + "</p>";
          } else {
            var resMsg = response
              .split('<div class="msg">')[1]
              .split("</div>")[0];
          }

          if (resMsg.indexOf("이미 구독 중인 이메일") !== -1) {
            STBSUBFORM.showError(
              "stb_email",
              response.split("ERROR_MSG:")[1].split("-->")[0]
            );
          } else if (resMsg.indexOf("이미 구독 중인 전화번호") !== -1) {
            STBSUBFORM.showError(
              "stb_$phone",
              response.split("ERROR_MSG:")[1].split("-->")[0]
            );
          } else {
            resultDom.style.display = "block";
            resultDom.innerHTML = resMsg;
            if (status === "success") {
              resultDom.className += " success";
            } else if (status === "fail") {
              resultDom.className += " fail";
            }
          }
          setTimeout(function () {
            STBSUBFORM.toggleButtonActivation(true);
          }, 2000);
        },
        openModal: function (e) {
          var modal = document.getElementById(e.target.dataset.modal);
          modal.className = modal.className.replace("blind", "");
        },
        closeModal: function (e) {
          var modal = document.getElementById(e.target.dataset.modal);
          modal.className += " blind";
        },
        openModalOld: function (e) {
          var modal = document.getElementById("stb_form_modal");
          modal.className = modal.className.replace("blind", "");
        },
        closeModalOld: function (e) {
          var modal = document.getElementById("stb_form_modal");
          modal.className += " blind";
        },
        toggleButtonActivation: function (state) {
          if (STBSUBFORM.submitButton === null) {
            return;
          }
          if (state) {
            STBSUBFORM.submitButton.classList.remove("disabled");
          } else {
            STBSUBFORM.submitButton.classList.add("disabled");
          }
        },
        josa: function (string) {
          return (string.charCodeAt(string.length - 1) - 0xac00) % 28 > 0
            ? "을"
            : "를";
        },
        deselect: function (e) {
          var targets = Array.from(
            document.getElementsByName(e.target.dataset.name)
          );
          targets.forEach((element) => (element.checked = false));

          STBSUBFORM.hideEtcInput(targets);
          STBSUBFORM.hideDeselectButton(e.target.dataset.name);
        },
        checkRadioSelected: function (e) {
          var targets = Array.from(document.getElementsByName(e.target.name));
          var selected = targets.filter((element) => element.checked === true);

          if (selected.length > 0) {
            STBSUBFORM.showDeselectButton(e.target.name);
            if (selected[0].value === "기타") {
              targets[targets.length - 1].parentNode.parentNode.style.display =
                "block";
            } else {
              STBSUBFORM.hideEtcInput(targets);
            }
          } else {
            STBSUBFORM.hideEtcInput(targets);
          }
        },
        hideEtcInput: function (targets) {
          if (targets.filter((e) => e.value === "기타").length > 0) {
            targets[targets.length - 1].parentNode.parentNode.style.display =
              "none";
          }
        },
        showDeselectButton: function (targetName) {
          STBSUBFORM.deselectButton.forEach((element) => {
            if (element.dataset.name === targetName) {
              element.style.display = "block";
            }
          });
        },
        hideDeselectButton: function (targetName) {
          STBSUBFORM.deselectButton.forEach((element) => {
            if (element.dataset.name === targetName) {
              element.style.display = "none";
            }
          });
        },
        form: document.getElementById("stb_subscribe_form"),
        modalOpenButton: document.querySelectorAll(".stb_form_modal_open_btn"),
        modalOpenButtonOld: document.querySelectorAll("#stb_form_modal_open"),
        modalCloseButton: document.querySelectorAll(
          ".stb_form_modal_close_btn"
        ),
        modalCloseButtonOld: document.querySelectorAll("#stb_form_modal_close"),
        modalBgButton: document.getElementById("stb_form_modal_bg"),
        phoneNumerInput: document.getElementById("stb_$phone"),
        submitButton: document.getElementById("stb_form_submit_button"),
        deselectButton: document.querySelectorAll(".deselect"),
        radioButton: document.querySelectorAll('input[type="radio"]'),
      };

      STBSUBFORM.form.addEventListener("submit", STBSUBFORM.formRequest);

      if (STBSUBFORM.radioButton !== null) {
        STBSUBFORM.radioButton.forEach(function (element) {
          element.addEventListener("change", STBSUBFORM.checkRadioSelected);
        });
      }
      if (STBSUBFORM.deselectButton !== null) {
        STBSUBFORM.deselectButton.forEach(function (element) {
          element.addEventListener("click", STBSUBFORM.deselect);
        });
      }
      // if (STBSUBFORM.modalOpenButton !== null) {
      //   STBSUBFORM.modalOpenButton.forEach(function (element) {
      //     element.addEventListener("click", STBSUBFORM.openModal);
      //   });
      // }
      // if (STBSUBFORM.modalCloseButton !== null) {
      //   STBSUBFORM.modalCloseButton.forEach(function (element) {
      //     element.addEventListener("click", STBSUBFORM.closeModal);
      //   });
      // }
      // if (STBSUBFORM.modalOpenButtonOld !== null) {
      //   STBSUBFORM.modalOpenButtonOld.forEach(function (element) {
      //     element.addEventListener("click", STBSUBFORM.openModalOld);
      //   });
      // }
      // if (STBSUBFORM.modalCloseButtonOld !== null) {
      //   STBSUBFORM.modalCloseButtonOld.forEach(function (element) {
      //     element.addEventListener("click", STBSUBFORM.closeModalOld);
      //   });
      // }
      // if (STBSUBFORM.modalBgButton !== null) {
      //   STBSUBFORM.modalBgButton.addEventListener(
      //     "click",
      //     STBSUBFORM.closeModal
      //   );
      // }
      // if (STBSUBFORM.phoneNumerInput !== null) {
      //   STBSUBFORM.phoneNumerInput.addEventListener(
      //     "keyup",
      //     STBSUBFORM.validatePhoneNumber
      //   );
      //   STBSUBFORM.phoneNumerInput.addEventListener(
      //     "keydown",
      //     STBSUBFORM.validatePhoneNumber
      //   );
      // }
      if (window.location.search.indexOf("groupIds") !== -1) {
        STBSUBFORM.form.action +=
          "?groupIds" + window.location.search.split("groupIds")[1];
      }

      if (window.location.host.indexOf("page.stibee.com") !== -1) {
        document.querySelector("#stb_subscribe").classList.add("theme-page");
      }
      // 각 슬라이드의 너비 값을 얻음
      const slideEl = document.querySelector(".slide"); // 슬라이드에 적합한 선택기로 교체
      if (slideEl) {
        this.state.slideWidth = slideEl.offsetWidth;
      }
    } catch (error) {
      this.error = error;
      console.error(error);
    }
  },
  methods: {
    setCookie() {
      Cookies.set("myCookie", "myValue", { sameSite: "Lax" });
    },
    calculateStatus(rows) {
      const now = new Date();
      rows.forEach((item) => {
        let startDate;
        let endDate;

        if (item.field_event_period.includes(" ~ ")) {
          const eventPeriod = item.field_event_period.split(" ~ ");
          startDate = new Date(eventPeriod[0]);
          endDate = new Date(eventPeriod[1]);
        } else {
          startDate = new Date(item.field_event_date);
          endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
        }
        if (startDate > now) {
          item.status = "모집중";
        } else if (startDate <= now && endDate >= now) {
          item.status = "모집중";
        } else {
          item.status = "마감";
        }
      });
    },
  },
  created() {
    this.setCookie();
  },
};
</script>

<style>
input:focus {
  outline: none;
}

/* 슬라이드 */
li.carousel__slide {
  width: 100% !important;
}
.carousel__pagination {
  margin-top: 0;
  padding-bottom: 56px;
  gap: 16px;
}
.carousel__pagination-button {
  padding: 0;
}
.carousel__pagination-button::after {
  width: 16px !important;
  height: 16px !important;
  background-color: white;
  border: 2px solid #dee2e6;
  border-radius: 100% !important;
}
.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  width: 16px !important;
  height: 16px !important;
  background-color: #5c328b;
  border: 0;
  border-radius: 100% !important;
  transition: all 0.5s ease-out;
}

/* 상태 창 */
.status {
  width: 58px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #5c328b;
  font-style: normal;
  font-family: "Pretendard-SemiBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.recruiting {
  background-color: #5c328b;
}
.closed {
  background-color: #bfbfbf;
}

/* 스티비 */
.checkbox_wrap span {
  position: absolute !important;
  top: 0 !important;
  max-width: 242px !important;
  margin: 0 auto !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  margin-left: 24px !important;
  box-sizing: content-box !important;
  font-style: normal !important;
  font-family: "Pretendard-Regular" !important;
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff") !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #495057 !important;
  transform: translateY(-4px) !important;
}
.checkbox_wrap span:hover {
  opacity: 0.8 !important;
}
#stb_policy {
  display: none !important;
}
#stb_policy[type="checkbox"] + label {
  display: inline-block !important;
  width: 18px !important;
  height: 18px !important;
  border: 1px solid #d7d7d7 !important;
  position: relative !important;
  cursor: pointer !important;
  background-color: white !important;
  background-image: url("@/assets/Images/svgIcons/checkbox_unchecked.svg") !important;
  background-size: 8px 8px !important;
  box-sizing: border-box !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  box-shadow: inset 0 0 2px #dee2e6 !important;
}

#stb_policy:checked + label::after {
  content: "" !important;
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  position: absolute !important;
  width: 18px !important;
  height: 18px !important;
  top: 50% !important;
  left: 50% !important;
  margin: auto 0 !important;
  transform: translate(-50%, -50%) !important;
  background-color: #5c328b !important;
  background-image: url("@/assets/Images/svgIcons/checkbox_checked.svg") !important;
  background-size: 8px 8px !important;
  box-sizing: border-box !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
</style>
