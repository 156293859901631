<template>
  <section>
    <div class="max-w-[1216px] w-[90%] mx-auto">
      <article class="md:pt-[96px] md:pb-[80px] py-[40px] border-bottom">
        <p class="mb-4 md:mb-8 reveal">
          <span class="BoldH4 md:text-[32px] md:leading-[40px]"
            >디캠프가 운영하는 혁신 창업 1번지
            <br />
            '프론트원 (FRONT1)'</span
          >
        </p>
        <p class="mb-8 RegularP RegularTiny">
          프론트원은 스타트업의 업무공간 뿐만아니라 스타트업 성장을 위한<br />
          멘토링, 커뮤니티, 네트워킹 이벤트 등 다양한 프로그램을 운영하고
          있습니다.<br />
          디데이, 개별 파트너사를 통해 입주기업을 선발합니다.
        </p>
        <div
          class="flex sm:flex-row flex-col BoldH5 text-Primary-default md:gap-[48px] gap-[16px]"
        >
          <a
            class="flex items-center gap-2 md:gap-1 horizontal-shake"
            href="https://dcamp.kr/event"
            target="_blank"
          >
            디데이 신청하기
            <img
              :src="require('@/assets/Images/svgIcons/arrow_right.svg')"
              alt="arrow_right"
            />
          </a>
          <a
            class="flex items-center gap-2 md:gap-1 horizontal-shake"
            href="https://dcamp.kr/event/official/community"
            target="_blank"
          >
            커뮤니티 프로그램
            <img
              :src="require('@/assets/Images/svgIcons/arrow_right.svg')"
              alt="arrow_right"
            />
          </a>
        </div>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] gap-8 md:py-[80px] py-[40px] border-bottom"
      >
        <div>
          <h3 class="BoldH3">입주 스타트업</h3>
        </div>
        <div>
          <div
            v-if="isLoading"
            class="grid gap-y-6 gap-4 md:gap-8 grid-cols-[1fr_1fr] md:grid-cols-[1fr_1fr_1fr_1fr]"
          >
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
          </div>
          <ul
            v-if="!isLoading"
            class="grid gap-y-6 gap-4 md:gap-8 grid-cols-[1fr_1fr] md:grid-cols-[1fr_1fr_1fr_1fr]"
          >
            <li
              class="grid items-start gap-2 auto-rows-min"
              v-for="item in tenantStartupList.rows"
              :key="item.nid"
            >
              <a
                class="hover:transition-all hover:text-Primary-default hover:duration-150"
                :href="item.field_st_web"
                target="_blank"
              >
                <h6
                  class="BoldH6 md:text-[20px] md:leading-[24px]"
                  v-html="
                    removeSubstrings(item.title, ['주식회사', '\\(주\\)'])
                  "
                ></h6>
              </a>
              <span
                class="RegularTiny md:text-[16px] md:leading-[24px] text-Neutrals-grey6"
                v-html="item.field_st_about"
              ></span>
            </li>
          </ul>
        </div>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] gap-8 md:pt-[80px] pt-[40px] md:pb-[96px] pb-[48px]"
      >
        <div>
          <h3 class="BoldH3">졸업 스타트업</h3>
        </div>

        <div>
          <div
            v-if="isLoading"
            class="grid gap-y-6 gap-4 md:gap-8 grid-cols-[1fr_1fr] md:grid-cols-[1fr_1fr_1fr_1fr]"
          >
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
            <div
              class="h-[56px] rounded-md bg-Neutrals-grey1 animate-pulse"
            ></div>
          </div>
          <ul
            v-if="!isLoading"
            class="grid gap-y-6 gap-4 md:gap-8 grid-cols-[1fr_1fr] md:grid-cols-[1fr_1fr_1fr_1fr]"
          >
            <li
              class="grid items-start gap-1 md:gap-2 auto-rows-min"
              v-for="item in graduationStartupList.rows"
              :key="item.nid"
            >
              <a
                class="hover:transition-all hover:text-Primary-default hover:duration-150"
                :href="item.field_st_web"
                target="_blank"
              >
                <h6
                  class="BoldH6 md:text-[20px] md:leading-[24px]"
                  v-html="
                    removeSubstrings(item.title, ['주식회사', '\\(주\\)'])
                  "
                ></h6>
              </a>
              <span
                class="RegularTiny md:text-[16px] md:leading-[24px] text-Neutrals-grey6"
                v-html="item.field_st_about"
              ></span>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "CompanyPage",
  data() {
    return {
      tenantStartupList: [],
      graduationStartupList: [],
      error: null,
      isLoading: false,
    };
  },
  computed: {
    defaultImg() {
      return ""; // default image
    },
  },
  async mounted() {
    try {
      this.isLoading = true; // set isLoading state to true before fetching data

      const [tenantStartupListResponse, graduationStartupListResponse] =
        await Promise.all([
          axios.get(
            process.env.VUE_APP_API_KEY +
              "api/center_startup/list?field_campus=5&field_tanent=1&langcode=ko"
          ),
          axios.get(
            process.env.VUE_APP_API_KEY +
              "api/center_startup/list?field_campus=5&field_tanent=2&langcode=ko"
          ),
        ]);

      this.tenantStartupList = tenantStartupListResponse.data;
      this.graduationStartupList = graduationStartupListResponse.data;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      // set isLoading state to false after data is fetched, with a delay of 1 second
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  },
  methods: {
    removeSubstrings(str, substringsToRemove) {
      substringsToRemove.forEach((substring) => {
        const regex = new RegExp(substring, "g");
        str = str.replace(regex, "").trim();
      });

      return str;
    },
    loadData() {
      this.isLoading = true;
      setTimeout(() => {
        // fetch data here
        this.isLoading = false;
      }, 1000);
    },
    setCookie() {
      Cookies.set("myCookie", "myValue", { sameSite: "Lax" });
    },
  },
  created() {
    this.setCookie();
  },
};
</script>

<style></style>
