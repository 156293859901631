<template>
  <header class="sticky top-0 w-full z-[100] bg-Neutrals-white">
    <div class="hidden border-bottom md:block">
      <div class="md:flex md:items-center md:justify-between md:max-w-[1216px] md:py-2 py-1 RegularTiny w-[90%] mx-auto text-Neutrals-grey7">
        <ul
          class="flex items-center"
        >
          <li>
            <a class="hover:opacity-80" href="https://dcamp.kr/" target="_blank"
              >디캠프</a
            >
          </li>
          <li>
            <div class="h-[12px] mx-4 border-left"></div>
          </li>
          <li>
            <a class="hover:opacity-80" href="https://front1.kr/">프론트원</a>
          </li>
        </ul>
        <div class="flex items-center gap-1">
          <img
              class=""
              :src="require('@/assets/Images/svgIcons/lang_EN.svg')"
              alt="lang_EN"
          />
          <a
              class="hover:opacity-80"
              href="/en"
              target="_blank"
          >EN</a
          >
        </div>
      </div>
    </div>
    <div class="border-bottom">
      <div
        class="max-w-[1216px] w-[90%] flex items-center justify-between md:py-8 py-4 mx-auto"
      >
        <router-link to="/" @click="closeGnb">
          <img
            class="hidden md:flex"
            :src="require('@/assets/Images/logo.svg')"
            alt="logo"
          />
          <img
            class="md:hidden"
            :src="require('@/assets/Images/Mlogo.svg')"
            alt="Mlogo"
          />
        </router-link>
        <ul
          class="md:flex items-center lg:gap-[80px] gap-[60px] menu-list-hover BoldH4 hidden"
        >
          <li>
            <router-link to="/frontone">프론트원</router-link>
          </li>
          <li>
            <router-link to="/facilities">주요시설</router-link>
          </li>
          <li>
            <router-link to="/company">입주기업</router-link>
          </li>
          <li>
            <router-link to="/contact">문의하기</router-link>
          </li>
        </ul>
        <a class="mobile-menu mobile">
          <div class="line1"></div>
          <div class="line2"></div>
          <div class="line3"></div>
        </a>
      </div>
    </div>
  </header>
  <div class="gnb-dd md:hidden">
    <div class="inner w-[90%] mx-auto">
      <nav class="flex flex-col justify-start pt-[40px] gnb">
        <ul class="mb-[48px]">
          <li class="block mb-6 list-none BoldH2 font-PretendardBold">
            <router-link to="/frontone" @click="closeGnb">프론트원</router-link>
          </li>
          <li class="block mb-6 list-none BoldH2 font-PretendardBold">
            <router-link to="/facilities" @click="closeGnb"
              >주요시설</router-link
            >
          </li>
          <li class="block mb-6 list-none BoldH2 font-PretendardBold">
            <router-link to="/company" @click="closeGnb">입주기업</router-link>
          </li>
          <li class="block mb-6 list-none BoldH2 font-PretendardBold">
            <router-link to="/contact" @click="closeGnb">문의하기</router-link>
          </li>
        </ul>
        <ul class="flex items-center text-Neutrals-grey7">
          <li>
            <a href="https://dcamp.kr/" target="_blank">디캠프</a>
          </li>
          <li>
            <div class="h-[12px] mx-4 border-left"></div>
          </li>
          <li>
            <a href="https://front1.kr/">프론트원</a>
          </li>
          <li>
            <div class="h-[12px] mx-4 border-left"></div>
          </li>
          <li class="flex gap-1 items-center">
            <img
                class=""
                :src="require('@/assets/Images/svgIcons/lang_EN.svg')"
                alt="lang_EN"
            />
            <a href="https://dcamp-global.web.app" target="_blank">EN</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "HeaderComponent",
  data() {
    return {};
  },
  mounted() {
    $(".mobile-menu").click(function () {
      $("body").toggleClass("menu-open");
    });
  },
  watch: {
    $route() {
      $("body").removeClass("menu-open");
    },
    closeGnb() {
      $("body").removeClass("menu-open");
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    closeGnb() {
      $("body").removeClass("menu-open");
    },
  },
};
</script>

<style>
.menu-list-hover li a.router-link-active.router-link-exact-active {
  color: #5c328b !important;
}

.mobile-menu.mobile {
  display: none;
}
.gnb-dd {
  position: fixed;
  top: 138px;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  z-index: 98;
  transform: translateY(-100%);
  transition: transform 0.4s;
}

.menu-hover .gnb-dd {
  transform: translateY(0);
  transition: transform 0.4s;
}
@media (max-width: 768px) {
  .mobile-menu.mobile {
    width: 20px;
    height: 18px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .mobile-menu .line1,
  .mobile-menu .line2,
  .mobile-menu .line3 {
    width: 100%;
    height: 2px;
    background-color: #000;
    border-radius: 4px;
    transition: all 0.4s;
  }
  .menu-open .mobile-menu .line1 {
    background-color: #000;
    transform: rotate(45deg) translate(6px, 5px);
    transition: all 0.4s;
  }
  .menu-open .mobile-menu .line2 {
    background-color: #000;
    opacity: 0;
    transition: all 0.4s;
  }
  .menu-open .mobile-menu .line3 {
    background-color: #000;
    transform: rotate(-45deg) translate(6px, -6px);
    transition: all 0.4s;
  }

  .gnb-dd {
    top: 57px;
    height: calc(100vh - 57px);
    overflow: auto;
    transform: translateY(-191%);
    transition: transform 0.4s;
  }
  .menu-open .gnb-dd {
    transform: translateY(0);
    transition: transform 0.4s;
  }
  .gnb-dd nav.gnb {
    width: 100%;
  }
}
</style>
