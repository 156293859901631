import { createRouter, createWebHistory } from "vue-router";
import main from "./views/MainPage.vue";
import en_main from "./views/en_MainPage.vue";
import frontone from "./views/FrontonePage.vue";
import en_frontone from "./views/en_FrontonePage.vue";
import facilities from "./views/FacilitiesPage.vue";
import en_facilities from "./views/en_FacilitiesPage.vue";
import company from "./views/CompanyPage.vue";
import en_company from "./views/en_CompanyPage.vue";
import contact from "./views/ContactPage.vue";
import en_contact from "./views/en_ContactPage.vue";

const routes = [
  {
    path: "/:lang?",
    name: "main",
    component: main,
  },
  {
    path: "/:lang?/en",
    name: "en_main",
    component: en_main,
  },
  {
    path: "/:lang?/en/frontone",
    name: "en_frontone",
    component: en_frontone,
  },
  {
    path: "/frontone",
    name: "frontone",
    component: frontone,
  },
  {
    path: "/facilities",
    name: "facilities",
    component: facilities,
  },
  {
    path: "/:lang?/en/facilities",
    name: "en_facilities",
    component: en_facilities,
  },
  {
    path: "/company",
    name: "company",
    component: company,
  },
  {
    path: "/:lang?/en/company",
    name: "en_company",
    component: en_company,
  },
  {
    path: "/contact",
    name: "contact",
    component: contact,
  },
  {
    path: "/:lang?/en/contact",
    name: "en_contact",
    component: en_contact,
  },
  { path: "/:pathMatch(.*)*", redirect: "/404" },
  // { path: "/:lang?/404", name: "notFound", component: en_404 },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return {
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  const lang = to.params.lang || 'ko'; // set default language to Korean
  // console.log("Lang value in beforeEach guard:", lang);
  if (lang === "en") {
    // set language to English
    document.documentElement.lang = "en";
  } else {
    // set language to Korean
    document.documentElement.lang = "ko";
  }
  next();
});

export default router;
