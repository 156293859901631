<template>
  <section>
    <div class="max-w-[1216px] w-[90%] mx-auto">
      <article class="md:pt-[96px] md:pb-[80px] py-[40px] border-bottom">
        <p class="mb-4 md:mb-8 reveal">
          <span class="BoldH4 md:text-[32px] md:leading-[40px]"
            >국내 최대 스타트업 액셀러레이팅 공간</span
          >
        </p>
        <p class="md:RegularP RegularTiny">
          프론트원은 창업가들이 온전히 업무에만 전념할 수 있도록<br />
          건물 밖으로 나가지 않아도 식주 해결이 가능한 빌리지(마을) 형태로
          디자인되었습니다.<br />
          국내 최대 규모의 창업 지원 센터로 130개 이상의 기업이 입주할 수
          있고,<br />
          네트워킹 공간과 복지공간에는 GX룸, 샤워실, 수면실을 비롯해<br />
          구내식당, 아이돌봄 서비스, 우편수발실 등을 갖추고 있습니다.
        </p>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] py-[40px] border-bottom"
      >
        <div>
          <h3 class="BoldH3">공간 구성</h3>
        </div>
        <div class="right">
          <ul class="horizontal-list">
            <li>
              <p>
                <span class="floor">ZONE 4</span>
                <span class="name">복지 공간</span>
              </p>
              <p>
                <span class="desc">20F</span>
                <span class="RegularH6">다목적홀, 세미나실</span>
                <span class="desc">19F</span>
                <span class="RegularH6"
                  >커뮤니티 라운지, 카페, GX룸, 샤워·수면실</span
                >
              </p>
            </li>
            <li>
              <p>
                <span class="floor">ZONE 3</span>
                <span class="name">스타트업 업무 공간</span>
              </p>
              <p>
                <span class="desc">18F</span>
                <span class="RegularH6">디캠프 / FRONT1 운영본부</span>
                <span class="desc">17F</span>
                <span class="RegularH6">디캠프 / 캡스톤파트너스</span>
                <span class="desc">16F</span>
                <span class="RegularH6"
                  >디캠프 / 프라이머 / UNIDO ITPO KOREA</span
                >
                <span class="desc">15F</span>
                <span class="RegularH6">디캠프</span>
                <span class="desc">14F</span>
                <span class="RegularH6">디캠프 / 한국산업은행</span>
                <span class="desc">13F</span>
                <span class="RegularH6">신용보증기금 NEST</span>
                <span class="desc">12F</span>
                <span class="RegularH6">신한퓨처스랩 / 한국핀테크지원센터</span>
                <span class="desc">11F</span>
                <span class="RegularH6">한국핀테크지원센터</span>
              </p>
            </li>
            <li>
              <p>
                <span class="floor">ZONE 2</span>
                <span class="name">개방형 창업지원 플랫폼</span>
              </p>
              <p>
                <span class="desc">8-10F</span>
                <span class="RegularH6">신용보증기금 영업점</span>
                <span class="desc">6-7F</span>
                <span class="RegularH6">ICT이노베이션 스퀘어</span>
                <span class="desc">5F</span>
                <span class="RegularH6">박병원홀, 컨퍼런스룸, 세미나실</span>
                <span class="desc">4F</span>
                <span class="RegularH6"
                  >한국성장금융 / SK플래닛 / 삼성 멀티캠퍼스</span
                >
              </p>
            </li>
            <li>
              <p>
                <span class="floor">ZONE 1</span>
                <span class="name">네트워킹 공간</span>
              </p>
              <p>
                <span class="desc">3F</span>
                <span class="RegularH6">프론트원 라운지</span>
                <span class="desc">1-2F</span>
                <span class="RegularH6"
                  >로비, 카페, 키즈존, 스마트메일센터</span
                >
                <span class="desc">B1F</span>
                <span class="RegularH6">구내식당, 편의시설</span>
              </p>
            </li>
          </ul>
        </div>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] pt-[40px] pb-[48px]"
      >
        <div>
          <h3 class="BoldH3">주요 시설</h3>
        </div>
        <ul
          class="grid md:grid-cols-[1fr_1fr] md:gap-y-[48px] gap-y-[24px] gap-x-[32px]"
        >
          <li
            class="list-none"
            v-for="(facility, facilityIndex) in facilities"
            :key="facilityIndex"
          >
            <div class="mb-6 pb-[56.88%] res-box-wrap">
              <div class="res-box">
                <img
                  class="object-cover w-full h-full"
                  :src="facility.image_src"
                  :alt="facility.facility_name"
                />
              </div>
            </div>
            <div class="flex items-center mb-2">
              <span
                class="px-2 mr-2 RegularTiny bg-Primary-default text-Neutrals-white"
              >
                {{ facility.zone }}
              </span>
              <h4 class="BoldH4">
                {{ facility.facility_name }}
                <span
                  v-if="
                    ['카페', '키즈존', '우편수발실'].includes(
                      facility.facility_name
                    )
                  "
                  >(<a
                    class="text-Primary-default hover:opacity-80"
                    :href="facility.facility_url"
                    target="_blank"
                  >
                    {{ facility.facility_subname }}</a
                  >)</span
                >
              </h4>
            </div>
            <span class="RegularH6 text-Neutrals-grey6">
              {{ facility.description }}
              <i
                v-if="facility.facility_name === '박병원홀'"
                class="block font-normal text-[90%] leading-[1.4] px-[0.8rem] py-[0.6rem] bg-[#f4f8fa] text-Neutrals-grey6 not-italic"
                >{{ facility.additional_explanation }}</i
              >
            </span>
          </li>
        </ul>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "FacilitiesPage",

  data() {
    return {
      facilities: [
        {
          image_src: require("@/assets/Images/front1_1.jpg"),
          zone: "3F",
          facility_name: "FRONT1 라운지",
          description: "멤버십 회원을 위한 업무 복합 공간",
        },
        {
          image_src: require("@/assets/Images/front1_2.jpg"),
          zone: "19F",
          facility_name: "커뮤니티 라운지",
          description: "카페, 수면실 등 입주사 편의를 위한 전용 공간",
        },
        {
          image_src: require("@/assets/Images/front1_8.jpg"),
          zone: "19F",
          facility_name: "GX룸·샤워실·수면실",
          description: "입주사를 위한 웰니스 프로그램을 제공하는 공간",
        },
        {
          image_src: require("@/assets/Images/front1_3.jpg"),
          zone: "1F & 19F",
          facility_name: "카페",
          facility_subname: "LOUNGE'X",
          facility_url: "https://www.loungex.co.kr/",
          description: "국내 최초의 바리스타 로봇 협업 카페",
        },
        {
          image_src: require("@/assets/Images/front1_4.jpg"),
          zone: "5F",
          facility_name: "박병원홀",
          description:
            "최대 200인을 수용하며 데모데이, 세미나, 컨퍼런스 등 다양하게 이용가능한 다목적 홀",
          additional_explanation:
            "박병원 초대 이사장은 성장 잠재력 있는 스타트업을 발굴・육성하기 위해 은행권청년창업재단을 설립했습니다.",
        },
        {
          image_src: require("@/assets/Images/front1_5.jpg"),
          zone: "5F",
          facility_name: "컨퍼런스룸",
          description:
            "20인 규모의 컨퍼런스룸은 화상회의, 기업설명회(IR) 등 심도있는 회의를 진행할 수 있는 공간",
        },
        {
          image_src: require("@/assets/Images/front1_11.jpg"),
          zone: "4F",
          facility_name: "비디오 & 사진 촬영 스튜디오",
          description: "비디오 및 사진 촬영이 가능한 입주사 전용 스튜디오",
        },
        {
          image_src: require("@/assets/Images/front1_6.jpg"),
          zone: "3F, 4F",
          facility_name: "세미나실 A,B,C",
          description:
            "최대 30인 규모로 교육, 세미나 등 행사 운영이 가능한 공간",
        },
        {
          image_src: require("@/assets/Images/front1_9.jpg"),
          zone: "1F",
          facility_name: "키즈존",
          facility_subname: "자란다",
          facility_url: "https://jaranda.kr/",
          description: "프론트원에서 일하는 부모님을 위한 키즈존",
        },
        {
          image_src: require("@/assets/Images/front1_12.jpeg"),
          zone: "1F",
          facility_name: "우편수발실",
          facility_subname: "DPOST",
          facility_url: "https://dpost.co.kr/",
          description:
            "프론트원 입주 기업을 위한 우편물 접수 및 보관, 택배・퀵 발송대행 등 스마트 우편 서비스 제공",
        },
        {
          image_src: require("@/assets/Images/front1_7.jpg"),
          zone: "B1F",
          facility_name: "구내식당",
          description: "누구나 이용가능한 구내 식당",
        },
      ],
    };
  },
};
</script>

<style>
.horizontal-list li {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr 1fr;
  grid-gap: 8px;
  padding: 24px 0;
  border-bottom: solid 1px #dee2e6;
  grid-template-columns: 1fr 1.25fr;
  align-items: start;
}
.horizontal-list li:first-child {
  padding-top: 0;
}
.horizontal-list li:last-child {
  padding-bottom: 0;
  border: 0;
}
.horizontal-list li p {
  display: grid;
  grid-gap: 8px 24px;
  grid-template-columns: auto 1fr;
}
.horizontal-list li p:nth-child(1) {
  grid-gap: 8px;
  word-break: keep-all;
}
.floor {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 8px;
  padding: 0 8px;
  height: 24px;
  font-size: 14px;
  color: #fff;
  background: #5c328b;
}
.name {
  font-style: normal;
  font-family: "Pretendard-SemiBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.desc {
  font-style: normal;
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #868e96;
}
@media (max-width: 768px) {
  .horizontal-list li {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}
</style>
