import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router.js";
import "./css/index.css";

import VueCookies from "vue3-cookies";

import jQuery from "jquery";
import { Tabs, Tab } from "vue3-tabs-component";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/service-worker.js");
  });
}

const i18n = createI18n({
  locale: "ko",
});

const app = createApp(App)
  .use(router)
  .use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None",
  })
  .component("tabs", Tabs)
  .component("tab", Tab);

app.use(i18n);

app.mount("#app");

global.$ = jQuery;
