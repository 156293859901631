<template>
  <div>
    <HeaderComponent v-if="!$route.name.includes('en_')" />
    <en_HeaderComponent v-else />
    <router-view />
    <FooterComponent v-if="!$route.name.includes('en_')" />
    <en_FooterComponent v-else />
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import en_HeaderComponent from "@/components/en_HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import en_FooterComponent from "@/components/en_FooterComponent";

export default {
  name: "App",
  components: {
    HeaderComponent,
    en_HeaderComponent,
    FooterComponent,
    en_FooterComponent,
  },
  created() {
    this.$cookies.set("theme", "default");
    this.$cookies.set("hover-time", "1s");
  },
  methods: {
    setCookie() {
      document.cookie = "myCookie=myValue; SameSite=Lax";
    },
  },
  computed: {
    lang() {
      const lang = this.$route.params.lang || "ko";
      // console.log("app.vue Current lang value:", lang);
      return lang;
    }
  },
  mounted() {
    // console.log("Translations for en_HeaderComponent:", this.$t);
  }
};
</script>

<style></style>
