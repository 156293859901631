<template>
  <footer class="bg-Neutrals-grey9 text-Neutrals-grey5">
    <div
      class="max-w-[1216px] flex sm:flex-row flex-col justify-between pt-[56px] lg:pb-[104px] pb-[80px] lg:px-0 w-[90%] mx-auto"
    >
      <div class="flex flex-col gap-6 RegularTiny">
        <div>
          <p class="BoldTiny">Banks Foundation for Young Entrepreneurs</p>
          <p>
            <span class="border leading-[18px] inline-block px-1 mx-auto">
              FRONT1
            </span>
            122, Mapo-daero, Mapo-gu, Seoul (FRONT1)
          </p>
          <p>
            CRN
            <span class="border leading-[18px] inline-block px-1 mx-auto">
              FRONT1
            </span>
            742-82-00395
          </p>
        </div>
        <p>
          © Banks Foundation for Young Entrenreneurs. All rights Reserved.
        </p>
      </div>
      <div class="flex flex-col gap-10">
        <div class="flex flex-col gap-2 mt-4 sm:mt-0 md:flex-row">
          <div class="select-tab" :class="{ active: isActive1 }">
            <div class="button" @click="toggleActive1">
              <p>FAMILY SITE</p>
              <span>
                <img
                  src="@/assets/Images/svgIcons/select_icon.svg"
                  alt="familysite selectbox arrows"
                />
              </span>
            </div>
            <ul>
<!--              <li>-->
<!--                <a href="https://front1.kr/" target="_blank">FRONT1 Korea</a>-->
<!--              </li>-->
              <li>
                <a
                  href="https://dcamp.recruiter.co.kr/appsite/company/index"
                  target="_blank"
                >
                  Employment</a
                >
              </li>
              <li>
                <a href="https://dcamp.kr/" target="_blank">
                  d・camp</a
                >
              </li>
<!--              <li>-->
<!--                <a href="https://dcamp.kr/en" target="_blank">-->
<!--                  d・camp Global</a-->
<!--                >-->
<!--              </li>-->
            </ul>
          </div>
          <div class="select-tab" :class="{ active: isActive2 }">
            <div class="button" @click="toggleActive2">
              <p>Related Institutions</p>
              <span>
                <img
                  src="@/assets/Images/svgIcons/select_icon.svg"
                  alt="familysite selectbox arrows"
                />
              </span>
            </div>
            <ul>
              <li>
                <a href="https://www.fsc.go.kr/index" target="_blank"
                  >Financial Policy Bureau</a
                >
              </li>
              <li>
                <a href="https://www.nts.go.kr/" target="_blank">National Tax Service</a>
              </li>
              <li>
                <a href="https://www.kfb.or.kr/main/main.php" target="_blank"
                  >Korea Federation of Banks</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="flex justify-start gap-2 sm:justify-end">
          <div
            class="w-10 h-10 border-[1px] border-[#DEE2E6] border-opacity-30 rounded-full hover:opacity-80"
          >
            <a
              class="flex items-center w-full h-full"
              href="https://www.facebook.com/dcamp.kr"
              target="_blank"
            >
              <img
                class="mx-auto"
                :src="require('@/assets/Images/svgIcons/facebook.svg')"
                alt="facebook"
              />
            </a>
          </div>
<!--          <div-->
<!--            class="w-10 h-10 border-[1px] border-[#DEE2E6] border-opacity-30 rounded-full hover:opacity-80"-->
<!--          >-->
<!--            <a-->
<!--              class="flex items-center w-full h-full"-->
<!--              href="https://twitter.com/Dcamp_kr"-->
<!--              target="_blank"-->
<!--            >-->
<!--              <img-->
<!--                class="mx-auto"-->
<!--                :src="require('@/assets/Images/svgIcons/twitter.svg')"-->
<!--                alt="twitter"-->
<!--              />-->
<!--            </a>-->
<!--          </div>-->
          <div
            class="w-10 h-10 border-[1px] border-[#DEE2E6] border-opacity-30 rounded-full hover:opacity-80"
          >
            <a
              class="flex items-center w-full h-full"
              href="https://www.youtube.com/channel/UCqsmYLUWkpphW-RgK9GZh7A"
              target="_blank"
            >
              <img
                class="mx-auto"
                :src="require('@/assets/Images/svgIcons/youtube.svg')"
                alt="youtube"
              />
            </a>
          </div>
          <div
              class="w-10 h-10 border-[1px] border-[#DEE2E6] border-opacity-30 rounded-full hover:opacity-80"
          >
            <a
                class="flex items-center w-full h-full"
                href="https://www.instagram.com/dcamp_official"
                target="_blank"
            >
              <img
                  class="mx-auto"
                  :src="require('@/assets/Images/svgIcons/instagram.svg')"
                  alt="instagram"
              />
            </a>
          </div>
          <div
              class="w-10 h-10 border-[1px] border-[#DEE2E6] border-opacity-30 rounded-full hover:opacity-80"
          >
            <a
                class="flex items-center w-full h-full"
                href="https://blog.naver.com/dcampdev"
                target="_blank"
            >
              <img
                  class="mx-auto"
                  :src="require('@/assets/Images/svgIcons/blog.svg')"
                  alt="naver blog"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      isActive1: false,
      isActive2: false,
    };
  },
  methods: {
    toggleActive1() {
      if (this.isActive2) {
        $(".select-tab:last-of-type", this.$el).toggleClass("active");
        this.isActive2 = false;
      }
      $(".select-tab:first-of-type", this.$el).toggleClass("active");
      this.isActive1 = !this.isActive1;
    },
    toggleActive2() {
      if (this.isActive1) {
        $(".select-tab:first-of-type", this.$el).toggleClass("active");
        this.isActive1 = false;
      }
      $(".select-tab:last-of-type", this.$el).toggleClass("active");
      this.isActive2 = !this.isActive2;
    },
  },
};
</script>

<style>
.select-tab {
  min-width: 224px;
  z-index: 1;
  position: relative;
}
.select-tab .button {
  width: 100%;
  height: 40px;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1;
}
.select-tab .button p {
  width: calc(100% - 40px);
  font-size: 14px;
  line-height: 24px;
  padding: 8px 16px;
  color: #ffffff;
  font-style: normal;
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.select-tab .button span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-left: 1px solid #ffffff;
}
.select-tab ul {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.select-tab.active ul {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: all 0.4s;
  position: absolute;
  width: 100%;
}
.select-tab ul li {
  padding: 8px 16px;
  border: 1px solid #fff;
  background-color: #000;
}
.select-tab ul li a {
  display: block;
  font-style: normal;
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
}
.select-tab:nth-of-type(1) {
  z-index: 2;
}
.select-tab:nth-of-type(2) {
  z-index: 1;
}
.select-tab.active .button span img {
  transform: rotate(-180deg);
  transition: transform 0.4s;
}
</style>
