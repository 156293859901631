<template>
  <section>
    <div class="max-w-[1216px] w-[90%] mx-auto">
      <article class="md:pt-[80px] pt-[40px]">
        <p class="reveal">
          <span class="BoldH4 md:text-[32px] md:leading-[40px]"
            >프론트원에 궁금한 점이 있으신가요?
          </span>
        </p>
      </article>
      <article class="md:pt-[48px] pt-[24px] md:pb-[96px] pb-[48px]">
        <div class="md:mb-[80px] mb-[40px]">
          <tabs>
            <tab name="전체">
              <div
                class="px-2 py-4 md:px-4 md:py-8 border-bottom"
                v-for="(item, index) in faqAll"
                :key="item.nid"
              >
                <button
                  :class="[
                    'flex',
                    'items-center',
                    'justify-between',
                    isOpen[index] ? 'active-class' : 'inactive-class',
                  ]"
                  @click="isOpen[index] = !isOpen[index]"
                >
                  <span v-html="item.title"></span>
                  <div
                    :class="[
                      'btn-round',
                      'animated',
                      {
                        closeButton: isOpen[index],
                        plusButton: !isOpen[index],
                      },
                    ]"
                  >
                    <span class="close"></span>
                  </div>
                </button>
                <collapse-transition>
                  <div
                    class="pt-4 RegularH6 text-Neutrals-grey7"
                    v-show="isOpen[index]"
                    v-html="item.body"
                  />
                </collapse-transition>
              </div>
            </tab>
            <tab
              v-for="(category, index) in categories"
              :key="index"
              :name="category.name"
            >
              <div
                class="px-4 py-8 border-bottom"
                v-for="(item, index) in category.data"
                :key="item.nid"
              >
                <button
                  :class="[
                    'flex',
                    'items-center',
                    'justify-between',
                    isOpen[index] ? 'active-class' : 'inactive-class',
                  ]"
                  @click="isOpen[index] = !isOpen[index]"
                >
                  <span v-html="item.title"></span>
                  <div
                    :class="[
                      'btn-round',
                      'animated',
                      {
                        closeButton: isOpen[index],
                        plusButton: !isOpen[index],
                      },
                    ]"
                  >
                    <span class="close"></span>
                  </div>
                </button>
                <collapse-transition>
                  <div
                    class="pt-4 RegularH6 text-Neutrals-grey7"
                    v-show="isOpen[index]"
                    v-html="item.body"
                  />
                </collapse-transition>
              </div>
            </tab>
          </tabs>
        </div>
        <div class="text-center">
          <h3 class="mb-3 md:mb-6 BoldH3">그 밖에 궁금한 점이 있으신가요?</h3>
          <p class="mb-5 md:mb-10 RegularP text-Neutrals-grey7">
            더 궁금하신 사항이 있다면 디캠프 홈페이지를 통해 문의해주세요.
          </p>
          <div>
            <a
              class="inline-block box-content border-Neutrals-grey9 border-solid border-[1px] BoldH6 px-[76px] py-3 hover:border-Neutrals-grey9 hover:bg-Neutrals-grey9 hover:text-Neutrals-white transition duration-300 ease-in-out"
              href="https://dcamp.kr/about/qna"
              target="_blank"
            >
              문의하기
            </a>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";

export default {
  name: "ContactPage",
  components: {
    CollapseTransition,
  },
  data() {
    return {
      faqAll: [],
      faq: [],
      error: null,
      isOpen: [],
    };
  },
  computed: {
    defaultImg() {
      return ""; // default image
    },
    categories() {
      const categoryNames = [
        "투자",
        "공간예약·대관",
        "공식 프로그램",
        "제휴협력·취재견학",
        // "멤버십·웹사이트",
      ];
      return this.faq.map((data, index) => ({
        name: categoryNames[index],
        data,
      }));
    },
  },
  async mounted() {
    try {
      const [
        faqAllResponse,
        faq1Response,
        faq2Response,
        faq3Response,
        faq4Response,
        // faq5Response,
      ] = await Promise.all([
        axios.get(process.env.VUE_APP_API_KEY + "api/about/faq?langcode=ko", {
          responseType: "arraybuffer",
        }),
        axios.get(
          process.env.VUE_APP_API_KEY + "api/about/faq?field_category=1&langcode=ko",
          {
            responseType: "arraybuffer",
          }
        ),
        axios.get(
          process.env.VUE_APP_API_KEY + "api/about/faq?field_category=2&langcode=ko",
          {
            responseType: "arraybuffer",
          }
        ),
        axios.get(
          process.env.VUE_APP_API_KEY + "api/about/faq?field_category=3&langcode=ko",
          {
            responseType: "arraybuffer",
          }
        ),
        axios.get(
          process.env.VUE_APP_API_KEY + "api/about/faq?field_category=4&langcode=ko",
          {
            responseType: "arraybuffer",
          }
        ),
        // axios.get(
        //   process.env.VUE_APP_API_KEY + "api/about/faq?field_category=5",
        //   {
        //     responseType: "arraybuffer",
        //   }
        // ),
      ]);

      const faqAllData = faqAllResponse.data;
      const faqData = [
        faq1Response.data,
        faq2Response.data,
        faq3Response.data,
        faq4Response.data,
        // faq5Response.data,
      ];

      const faqAllDecoder = new TextDecoder("utf-8");
      this.faqAll = JSON.parse(
        faqAllDecoder.decode(new Uint8Array(faqAllData))
      );

      const faqDecoder = new TextDecoder("utf-8");
      this.faq = faqData.map((faq) =>
        JSON.parse(faqDecoder.decode(new Uint8Array(faq)))
      );
    } catch (error) {
      this.error = error;
      console.error(error);
    }
  },

  methods: {
    setCookie() {
      Cookies.set("myCookie", "myValue", { sameSite: "Lax" });
    },
  },
  created() {
    this.setCookie();
  },
};
</script>

<style>
.tabs-component {
  width: 100%;
}
ul.tabs-component-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  height: 88px;
  border-bottom: 1px solid #000000;
  gap: 24px;
  font-style: normal;
  font-family: "Pretendard-SemiBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #868e96;
}
a.tabs-component-tab-a:hover {
  color: #000000;
  text-decoration: underline;
  text-underline-offset: 4px;
  transition: all 0.3s ease-in-out;
}
a.tabs-component-tab-a.is-active {
  color: #000000;
  text-decoration: underline;
  text-underline-offset: 4px;
}

div.tabs-component-panels button {
  width: 100%;
  font-size: 20px;
  line-height: 32px;
  font-family: "Pretendard-SemiBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
  color: #000000;
  transition: all 0.1s ease-in-out;
  outline: none;
  text-align: left;
  background-position: center right;
  background-repeat: no-repeat;
}
div.tabs-component-panels button:hover {
  color: #5c328b;
  transition: all 0.3s ease-in-out;
}
div.tabs-component-panels button.active-class {
  color: #5c328b;
}

div.tabs-component-panels button span {
  font-style: normal;
  font-family: "Pretendard-SemiBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

@media (max-width: 768px) {
  .tabs-component-tabs {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  .tabs-component-tabs::-webkit-scrollbar {
    display: none; /* 크롬, 사파리, 오페라, 엣지 */
  }
  ul.tabs-component-tabs {
    height: 44px;
    padding-bottom: 12px;
    gap: 20px;
  }

  div.tabs-component-panels button span {
    font-style: normal;
    font-family: "Pretendard-SemiBold";
    src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
      format("woff");
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
}

/* FAQ plus and close button animation */
.btn-round {
  width: 24px;
  height: 24px;
}
.close {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  overflow: hidden;
  color: #484b4c;
}
.close::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #484b4c;
}
.close::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #484b4c;
}
.close::before {
  transform: rotate(0deg);
}
.close::after {
  transform: rotate(90deg);
}
.animated {
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@media (max-width: 768px) {
  .close {
    top: -5px;
    left: 50%;
  }
  .close::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 50%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #484b4c;
  }
  .close::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 50%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #484b4c;
  }
}

@-webkit-keyframes plusButton {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    background-color: white;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: white;
  }
}

@keyframes plusButton {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background-color: white;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: white;
  }
}

.plusButton {
  -webkit-animation-name: plusButton;
  animation-name: plusButton;
}

@-webkit-keyframes closeButton {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: white;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background-color: white;
  }
}
@keyframes closeButton {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    background-color: white;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background-color: white;
  }
}

.closeButton {
  -webkit-animation-name: closeButton;
  animation-name: closeButton;
}

@-webkit-keyframes circleShape {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    background-color: white;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background-color: white;
    border-radius: 50px;
  }
}

@keyframes circleShape {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    background-color: white;
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    background-color: white;
    border-radius: 50px;
  }
}
</style>
