<template>
  <section>
    <div class="max-w-[1216px] w-[90%] mx-auto">
      <article class="md:pt-[96px] md:pb-[80px] py-[40px] border-bottom">
        <p class="mb-4 md:mb-8 reveal">
          <span class="BoldH4 md:text-[32px] md:leading-[40px]"
            >Korea’s Largest Startup Accelerating Hub</span
          >
        </p>
        <p class="md:RegularP RegularTiny">
          FRONT1 has been designed to offer various services and amenities for its startup residents.
          <br />
          Startups can enjoy facilities including our cafeteria, postal office, childcare center, lounge areas, fitness center, and more.
          <br class="hidden md:block" />
          The building holds over 130 companies at any given time as the largest startup hub.
          <br class="hidden md:block" />
        </p>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] py-[40px] border-bottom"
      >
        <div>
          <h3 class="BoldH3">Space</h3>
        </div>
        <div class="right">
          <ul class="horizontal-list">
            <li>
              <p>
                <span class="floor">ZONE 4</span>
                <span class="name">WELFARE ZONE</span>
              </p>
              <p>
                <span class="desc">20F</span>
                <span class="RegularH6">Multi-Purpose Hall, Seminar Room</span>
                <span class="desc">19F</span>
                <span class="RegularH6"
                  >Community Lounge, Café, GX Room, Shower & Sleeping Room</span
                >
              </p>
            </li>
            <li>
              <p>
                <span class="floor">ZONE 3</span>
                <span class="name">STARTUP WORKSPACE</span>
              </p>
              <p>
                <span class="desc">18F</span>
                <span class="RegularH6">d.camp / FRONT1 Operational HQ</span>
                <span class="desc">17F</span>
                <span class="RegularH6">d.camp / Capstone Partners</span>
                <span class="desc">16F</span>
                <span class="RegularH6"
                  >d.camp / Primer / UNIDO ITPO KOREA</span
                >
                <span class="desc">15F</span>
                <span class="RegularH6">d.camp</span>
                <span class="desc">14F</span>
                <span class="RegularH6">d.camp / Korea Development Bank</span>
                <span class="desc">13F</span>
                <span class="RegularH6">Korea Credit Guarantee Fund NEST</span>
                <span class="desc">12F</span>
                <span class="RegularH6"
                  >Shinhan Future’s Lab / Fintech Center Korea</span
                >
                <span class="desc">11F</span>
                <span class="RegularH6">Fintech Center Korea</span>
              </p>
            </li>
            <li>
              <p>
                <span class="floor">ZONE 2</span>
                <span class="name">OPEN PLATFORM FOR STARTUP SUPPORT</span>
              </p>
              <p>
                <span class="desc">8-10F</span>
                <span class="RegularH6"
                  >Korea Credit Guarantee Fund Branch</span
                >
                <span class="desc">6-7F</span>
                <span class="RegularH6">ICT Innovation Square</span>
                <span class="desc">5F</span>
                <span class="RegularH6"
                  >Bahk Byong-won Hall, Conference Room, Seminar Room</span
                >
                <span class="desc">4F</span>
                <span class="RegularH6"
                  >K-Growth / SK Planet / Samsung Multi-Campus</span
                >
              </p>
            </li>
            <li>
              <p>
                <span class="floor">ZONE 1</span>
                <span class="name">NETWORKING ZONE</span>
              </p>
              <p>
                <span class="desc">3F</span>
                <span class="RegularH6">FRONT1 Lounge</span>
                <span class="desc">1-2F</span>
                <span class="RegularH6"
                  >Lobby, Café, Kids Zone, Smart Mail Center</span
                >
                <span class="desc">B1F</span>
                <span class="RegularH6">Cafeteria, Amenities</span>
              </p>
            </li>
          </ul>
        </div>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] pt-[40px] pb-[48px]"
      >
        <div>
          <h3 class="BoldH3">Major Facilities</h3>
        </div>
        <ul
          class="grid md:grid-cols-[1fr_1fr] md:gap-y-[48px] gap-y-[24px] gap-x-[32px]"
        >
          <li
            class="list-none"
            v-for="(facility, facilityIndex) in facilities"
            :key="facilityIndex"
          >
            <div class="mb-6 pb-[56.88%] res-box-wrap">
              <div class="res-box">
                <img
                  class="object-cover w-full h-full"
                  :src="facility.image_src"
                  :alt="facility.facility_name"
                />
              </div>
            </div>
            <div class="flex items-start mb-2">
              <span
                class="px-2 mr-2 RegularTiny bg-Primary-default text-Neutrals-white"
              >
                {{ facility.zone }}
              </span>
              <h4 class="BoldH4">
                {{ facility.facility_name }}
                <span
                  v-if="
                    ['Café', 'Kids Zone', 'Mail Center'].includes(
                      facility.facility_name
                    )
                  "
                  >(<a
                    class="text-Primary-default hover:opacity-80"
                    :href="facility.facility_url"
                    target="_blank"
                  >
                    {{ facility.facility_subname }}</a
                  >)</span
                >
              </h4>
            </div>
            <span class="RegularH6 text-Neutrals-grey6">
              {{ facility.description }}
              <i
                v-if="facility.facility_name === 'Bahk Byong-won Hall'"
                class="block font-normal text-[90%] leading-[1.4] px-[0.8rem] py-[0.6rem] bg-[#f4f8fa] text-Neutrals-grey6 not-italic"
                >{{ facility.additional_explanation }}</i
              >
            </span>
          </li>
        </ul>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "FacilitiesPage",

  data() {
    return {
      facilities: [
        {
          image_src: require("@/assets/Images/front1_1.jpg"),
          zone: "3F",
          facility_name: "FRONT1 Lounge",
          description: "a working complex for members",
        },
        {
          image_src: require("@/assets/Images/front1_2.jpg"),
          zone: "19F",
          facility_name: "Community Lounge",
          description:
            "an area equipped with a café, sleeping room for resident startups only",
        },
        {
          image_src: require("@/assets/Images/front1_8.jpg"),
          zone: "19F",
          facility_name: "GX Room · Shower Room · Sleeping Room",
          description:
            "a dedicated space for resident startups, offering wellness programs",
        },
        {
          image_src: require("@/assets/Images/front1_3.jpg"),
          zone: "1F & 19F",
          facility_name: "Café",
          facility_subname: "LOUNGE'X",
          facility_url: "https://www.loungex.co.kr/",
          description: "Korea’s first café collaborating with a barista robot",
        },
        {
          image_src: require("@/assets/Images/front1_4.jpg"),
          zone: "5F",
          facility_name: "Bahk Byong-won Hall",
          description:
            "a multi-purpose hall that can be used for various purposes, including demo days, seminars, conferences, etc. (up to 200 persons)",
          additional_explanation:
            "The first chairman of the board of Bahk Byong-won took part in establishing the Banks Foundation for Young Entrepreneurs to discover and nurture startups with high growth potential.",
        },
        {
          image_src: require("@/assets/Images/front1_5.jpg"),
          zone: "5F",
          facility_name: "Conference Room",
          description:
            "a space for in-depth meetings, such as video conferences and IR events (up to 20 persons)",
        },
        {
          image_src: require("@/assets/Images/front1_11.jpg"),
          zone: "4F",
          facility_name: "Video & Photo Shooting Studio",
          description:
            "a studio where resident startups can shoot videos and photos.",
        },
        {
          image_src: require("@/assets/Images/front1_6.jpg"),
          zone: "3F, 4F",
          facility_name: "Seminar Room A, B, C",
          description:
            "a space for events, including education, seminars, etc. (up to 30 persons)",
        },
        {
          image_src: require("@/assets/Images/front1_9.jpg"),
          zone: "1F",
          facility_name: "Kids Zone",
          facility_subname: "Jaranda",
          facility_url: "https://jaranda.kr/",
          description: "a kids zone for parents who work at FRONT1",
        },
        {
          image_src: require("@/assets/Images/front1_12.jpeg"),
          zone: "1F",
          facility_name: "Mail Center",
          facility_subname: "DPOST",
          facility_url: "https://dpost.co.kr/",
          description:
            "Offers smart mailing services for FRONT1 resident startups, including mail posting and storage, parcel delivery & quick delivery agent, etc.",
        },
        {
          image_src: require("@/assets/Images/front1_7.jpg"),
          zone: "B1F",
          facility_name: "Cafeteria",
          description: "open to all",
        },
      ],
    };
  },
};
</script>

<style>
.horizontal-list li {
  display: grid;
  /*align-items: center;*/
  /*grid-template-columns: auto 1fr 1fr;*/
  grid-gap: 8px;
  padding: 24px 0;
  border-bottom: solid 1px #dee2e6;
  grid-template-columns: 1fr 1.25fr;
  align-items: start;
}
.horizontal-list li:first-child {
  padding-top: 0;
}
.horizontal-list li:last-child {
  padding-bottom: 0;
  border: 0;
}
.horizontal-list li p {
  display: grid;
  grid-gap: 8px 24px;
  grid-template-columns: auto 1fr;
}
.horizontal-list li p:nth-child(1) {
  grid-gap: 8px;
  word-break: keep-all;
}
.floor {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin-right: 8px;
  padding: 0 8px;
  height: 24px;
  font-size: 14px;
  color: #fff;
  background: #5c328b;
}
.name {
  font-style: normal;
  font-family: "Pretendard-SemiBold";
  /*src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")*/
  /*  format("woff");*/
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.desc {
  font-style: normal;
  font-family: "Pretendard-Regular";
  /*src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")*/
  /*  format("woff");*/
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #868e96;
}
@media (max-width: 768px) {
  .horizontal-list li {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}
</style>
