<template>
  <section>
    <div class="max-w-[1216px] w-[90%] mx-auto">
      <article class="md:pt-[96px] md:pb-[80px] py-[40px] border-bottom">
        <p class="mb-4 md:mb-8 reveal">
          <span class="BoldH4 md:text-[32px] md:leading-[40px]"
            >FRONT1:
            <br />
            A Leading Innovation Hub by d·camp</span
          >
        </p>
        <p class="mb-8 md:RegularP RegularTiny">
          FRONT1 opened in July 2020 and is a reliable basecamp for entrepreneurs looking to challenge the status quo.
          <br />
          It operates growth programs for startups together with private partners.
        </p>
        <div
          class="flex sm:flex-row flex-col BoldH5 text-Primary-default md:gap-[48px] gap-[16px]"
        >
          <a
            class="flex items-center gap-2 md:gap-1 horizontal-shake"
            href="https://dcamp.kr/en"
            target="_blank"
          >
            About d·camp
            <img
              :src="require('@/assets/Images/svgIcons/arrow_right.svg')"
              alt="arrow_right"
            />
          </a>
        </div>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] py-[40px] border-bottom"
      >
        <div>
          <h3 class="BoldH3">History</h3>
        </div>
        <div
          class="grid lg:grid-cols-[1fr_1fr] lg:gap-4 grid-cols-1 md:gap-[56px] gap-[24px]"
        >
          <ul
            class="grid lg:block lg:gap-8 auto-rows-min md:grid-cols-[1fr_1fr] md:gap-4"
          >
            <li>
              <span class="inline-block mb-2 text-Neutrals-grey6 RegularH6">
                Joint contributions by 19 financial institutions from the banking industry
              </span>
              <h3 class="mb-2 lg:mb-8 BoldH3">
                Cumulative contribution&nbsp;
                <span class="text-Primary-default">$680M</span>
              </h3>
            </li>
            <li>
              <span class="inline-block mb-2 text-Neutrals-grey6 RegularH6">
                Only Monthly Demo Day in Korea
              </span>
              <h3 class="mb-2 lg:mb-8 BoldH3">
                Startups
                <span class="text-Primary-default">7,000+ applications</span>
              </h3>
            </li>
            <li>
              <span class="inline-block mb-2 text-Neutrals-grey6 RegularH6">
                Programs that help startups grow
              </span>
              <h3 class="mb-2 lg:mb-8 BoldH3">
                Official programs&nbsp;
                <span class="text-Primary-default">1,700+</span>
              </h3>
            </li>
            <li>
              <span class="inline-block mb-2 text-Neutrals-grey6 RegularH6">
                Fund investments to create a startup ecosystem
              </span>
              <h3 class="mb-2 lg:mb-8 BoldH3">
                Trickle down effects&nbsp;
                <span class="text-Primary-default">$10B+</span>
              </h3>
            </li>
          </ul>
          <ul class="flex flex-col md:gap-4 RegularH6">
            <li v-for="item in historyList" :key="item.nid">
              <dl class="grid md:gap-[44px] gap-[18px] grid-cols-[72px_auto]">
                <dt class="RegularH6 text-Neutrals-grey6">
                  {{ item.field_year }}. {{ item.field_month }}
                </dt>
                <dd class="RegularH6">{{ item.title }}</dd>
              </dl>
            </li>
          </ul>
        </div>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] py-[40px] border-bottom"
      >
        <div>
          <h3 class="BoldH3">Partner Institutions</h3>
        </div>
        <ul
          class="grid grid-cols-[1fr_1fr] md:grid-cols-[1fr_1fr_1fr] lg:grid-cols-[1fr_1fr_1fr_1fr] md:gap-8 gap-y-6 gap-4"
        >
          <li
            class="grid gap-2"
            v-for="item in partnersList.rows"
            :key="item.nid"
          >
            <a :href="item.field_url" target="_blank">
              <div class="h-[64px] pb-[31.68%] res-box-wrap border">
                <div class="res-box">
                  <img
                    class="w-[75%] h-[75%] object-cover"
                    :src="'https://dcamp.kr' + item.field_st_logo"
                    :alt="item.title"
                  />
                </div>
              </div>
            </a>
            <a :href="item.field_url" target="_blank">
              <span class="RegularH6 block text-center text-[#868E96]">{{
                item.title
              }}</span>
            </a>
          </li>
        </ul>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] py-[40px] border-bottom"
      >
        <div>
          <h3 class="BoldH3">Introduction Video</h3>
        </div>
        <ul
          class="grid md:grid-cols-[1fr_1fr] md:gap-y-[48px] gap-y-[24px] gap-x-[32px]"
        >
          <li class="list-none">
            <div class="res-box-wrap img">
              <div class="res-box">
                <iframe
                  width="{436}"
                  height="{248}"
                  class="w-full h-full"
                  src="https://www.youtube.com/embed/6UeObetjXEo"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <h4 class="BoldH6 md:text-[20px] md:leading-[24px]">
                [d·day] 2023 d·day Benefits (feat. Residency, Investments, and Promotions)
              </h4>
            </div>
            <span class="RegularH6 text-Neutrals-grey6">
              2023 d·camp d·day Applications Open
            </span>
          </li>
          <li class="list-none">
            <div class="res-box-wrap img">
              <div class="res-box">
                <iframe
                  width="{436}"
                  height="{248}"
                  class="w-full h-full"
                  src="https://www.youtube.com/embed/CpJWjFE1H6M"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <h4 class="BoldH6 md:text-[20px] md:leading-[24px]">
                [d·day] A to Z on d·day Benefits
              </h4>
            </div>
            <span class="RegularH6 text-Neutrals-grey6">
              D.QUIZ: Check Out Everything from How to Apply, Residency, and Investment Benefits of d·day!
            </span>
          </li>
          <li class="list-none">
            <div class="res-box-wrap img">
              <div class="res-box">
                <iframe
                  width="{436}"
                  height="{248}"
                  class="w-full h-full"
                  src="https://www.youtube.com/embed/yIZygE37bSo"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <h4 class="BoldH6 md:text-[20px] md:leading-[24px]">
                A Visit to Front1 (feat. Piggy)
              </h4>
            </div>
            <span class="RegularH6 text-Neutrals-grey6">
              Front1, Korea’s Largest Center for Startups!
            </span>
          </li>
<!--          <li class="list-none">-->
<!--            <div class="res-box-wrap img">-->
<!--              <div class="res-box">-->
<!--                <iframe-->
<!--                  width="{436}"-->
<!--                  height="{248}"-->
<!--                  class="w-full h-full"-->
<!--                  src="https://www.youtube.com/embed/LR4O_0FNfdg"-->
<!--                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                ></iframe>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="flex items-center mb-2">-->
<!--              <h4 class="BoldH6 md:text-[20px] md:leading-[24px]">-->
<!--                프론트원 개관식 스케치 대공개-->
<!--              </h4>-->
<!--            </div>-->
<!--            <span class="RegularH6 text-Neutrals-grey6">-->
<!--              프론트원 개관! 그 역사적인 현장으로 돌아가볼까요?-->
<!--            </span>-->
<!--          </li>-->
<!--          <li class="list-none">-->
<!--            <div class="res-box-wrap img">-->
<!--              <div class="res-box">-->
<!--                <iframe-->
<!--                  width="{436}"-->
<!--                  height="{248}"-->
<!--                  class="w-full h-full"-->
<!--                  src="https://www.youtube.com/embed/q-hJcvXf-CI"-->
<!--                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
<!--                ></iframe>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="flex items-center mb-2">-->
<!--              <h4 class="BoldH6 md:text-[20px] md:leading-[24px]">-->
<!--                청년 원정대 -마포 프론트원 편- -->
<!--              </h4>-->
<!--            </div>-->
<!--            <span class="RegularH6 text-Neutrals-grey6">-->
<!--              금융지원부터 교육, 컨설팅, 해외진출, 주거공간까지!-->
<!--            </span>-->
<!--          </li>-->
          <li class="list-none">
            <div class="res-box-wrap img">
              <div class="res-box">
                <iframe
                  width="{436}"
                  height="{248}"
                  class="w-full h-full"
                  src="https://www.youtube.com/embed/Cvk5s7flCHE"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
            <div class="flex items-center mb-2">
              <h4 class="BoldH6 md:text-[20px] md:leading-[24px]">
                How Much Did Pengsoo Earn a Day with a Startup?
              </h4>
            </div>
            <span class="RegularH6 text-Neutrals-grey6">
              The Beat Penguin at Front1! CEO Pengsoo
            </span>
          </li>
        </ul>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] py-[40px] border-bottom"
      >
        <div>
          <h3 class="BoldH3">BI</h3>
        </div>
        <div>
          <div class="mb-6 pb-[27.43%] res-box-wrap">
            <div class="border res-box">
              <img
                class="object-contain w-full h-full"
                :src="require('@/assets/Images/logo-front1.svg')"
                alt="default"
              />
            </div>
          </div>
          <div class="mb-4">
            <h4 class="BoldH6 md:text-[20px] md:leading-[24px]">
              Front1, the No. 1 Hub for Innovative Startups
            </h4>
          </div>
          <span class="RegularH6 text-Neutrals-grey6">
            Front1's BI signifies the "first" steps of the startup spirit of taking on challenges, innovation, and growth, and being at the "front" of realizing new ideas and pioneering new business fields.
          </span>
          <div class="flex mt-4 md:mt-8">
            <a
              class="flex items-center gap-1 BoldH5 text-Primary-default vertical-shake"
              href="https://dcamp.kr/themes/custom/dcamp/docs/front1_logo.zip"
              download="front1_logo.zip"
            >
              Download Logo
              <div>
                <img
                  :src="require('@/assets/Images/svgIcons/arrow_down.svg')"
                  alt="arrow_down"
                />
              </div>
            </a>
          </div>
        </div>
      </article>
      <article
        class="grid md:grid-cols-[1fr_3.2fr] md:gap-8 gap-4 md:py-[80px] py-[40px]"
      >
        <div>
          <h3 class="BoldH3">Directions</h3>
        </div>
        <div>
          <div class="hidden md:flex mb-8 max-h-[560px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6326.82382290466!2d126.95254699999998!3d37.545358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c99805690c25d%3A0x27e9e65a72a2287a!2z7ZSE66Gg7Yq47JuQ!5e0!3m2!1sen!2sus!4v1683558156124!5m2!1sen!2sus"
              width="100%"
              height="560"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="md:hidden mb-8 max-h-[400px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6326.82382290466!2d126.95254699999998!3d37.545358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c99805690c25d%3A0x27e9e65a72a2287a!2z7ZSE66Gg7Yq47JuQ!5e0!3m2!1sen!2sus!4v1683558156124!5m2!1sen!2sus"
              width="100%"
              height="400"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div class="flex flex-col gap-2">
            <dl class="grid grid-cols-[120px_auto] md:gap-4 gap-2">
              <dt class="BoldH6">Address</dt>
              <dd class="RegularH6 text-Neutrals-grey8">
                122, Mapo-daero, Mapo-gu, Seoul (FRONT1) Postal Code 04213
              </dd>
            </dl>
            <dl class="grid grid-cols-[120px_auto] md:gap-4 gap-2">
              <dt class="BoldH6">Service Hours</dt>
              <dd class="RegularH6 text-Neutrals-grey8">
                Weekdays 09:00-22:00 / Saturdays 09:00-18:00 Startup workspace
                is open 24/7.
              </dd>
            </dl>
            <dl class="grid grid-cols-[120px_auto] md:gap-4 gap-2">
              <dt class="BoldH6">Tours</dt>
              <dd class="RegularH6 text-Neutrals-grey8">
                FRONT1 does not offer tour programs. If you have questions on
                visiting Front1, please contact us through the "<a
                  class="text-Primary-default hover:underline underline-offset-2"
                  href="https://dcamp.kr/about/qna"
                  target="_blank"
                  >Contact</a
                >" menu on our website.
              </dd>
            </dl>
            <dl class="grid grid-cols-[120px_auto] md:gap-4 gap-2">
              <dt class="BoldH6">Parking</dt>
              <dd class="RegularH6 text-Neutrals-grey8">
                Only pre-registered vehicles can enter and park in the
                underground parking lot. For parking inquiries, please contact
                the organizer.
              </dd>
            </dl>
          </div>
        </div>
      </article>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
  name: "FrontonePage",
  data() {
    return {
      historyList: [],
      partnersList: [],
      error: null,
    };
  },
  computed: {
    defaultImg() {
      return ""; // default image
    },
  },
  async mounted() {
    try {
      const [historyListResponse, partnersListResponse] = await Promise.all([
        axios.get(process.env.VUE_APP_API_KEY + "api/node/history/list?langcode=en"),
        axios.get(
          process.env.VUE_APP_API_KEY + "api/partner/list?field_campus=5&langcode=en"
        ),
      ]);

      this.historyList = historyListResponse.data;
      this.partnersList = partnersListResponse.data;
    } catch (error) {
      this.error = error;
      console.error(error);
    }
  },
  methods: {
    setCookie() {
      Cookies.set("myCookie", "myValue", { sameSite: "Lax" });
    },
  },
  created() {
    this.setCookie();
  },
};
</script>

<style></style>
